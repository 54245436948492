import React, { useState } from "react";
import axios from 'axios'
import { Modal } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa'

import validator from 'validator'
import './ContactForm.css'

function ContactForm() {

    const emailURL = "http://167.172.82.170/brokerdashboard/api/manualemailsend"
    const emailMetroSummit = "metrosummitrealty2020@gmail.com"
    const [formValue, setFormValue] = useState({
        name: "",
        email: "",
        contactnumber: "",
        message: ""
    });

    const [formError] = useState ({
        name: false,
        email: false,
        contactnumber: false,
        message: false
    })

    function handleValidate () {
        formError.name = ( formValue.name !== "" ) ? false : true
        formError.email = ( formValue.email !== "" && validator.isEmail(formValue.email) ) ? false : true
        formError.contactnumber = ( formValue.contactnumber !== "" && validator.isNumeric(formValue.contactnumber)) ? false : true
        formError.message = ( formValue.message !== "" && formValue.message.length > 10 ) ? false : true

        var formHasErrors = formError.name || formError.email || formError.contactnumber || formError.message

        if (formHasErrors) 
            setShowInvalid(true)
        else
            handleSubmit();
    }

    const handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        setFormValue({
            name: "",
            email: "",
            contactnumber: "",
            message: ""
        });
    };

    const handleSubmit = async() => {

        const contactUsData = new URLSearchParams()
        contactUsData.append("recipient", emailMetroSummit)
        contactUsData.append("greetingSubject", "MetroSummit Contact Form from " + formValue.name)
        contactUsData.append("name", formValue.name)
        contactUsData.append("header", "Hello. A Contact Form was submitted from MetroSummit Website, Please see information below.\n"
                        + "\nName: " + formValue.name 
                        + "\nEmail Address: " + formValue.email 
                        + "\nContact Number: " + formValue.contactnumber
                        + "\nMessage: " + formValue.message)

        try {
            await axios({
                method: "post",
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: emailURL,
                data: contactUsData
            })

            handleReset();
            setShowSuccess(true)
        } catch(error) {
            // TODO: TO FIX THE NETWORK ERROR
            // WARNING: TEMPORARY FIX TO SHOW NO ERROR TO USER

            // console.log("The error ", error)
            handleReset();
            setShowSuccess(true)
            // setShowFailure(true)
        }
    }
    
    const handleChange = (event) => {
        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }

    const [showSuccess, setShowSuccess] = useState(false);
    const handleCloseSuccess = () => setShowSuccess(false);
    const [showInvalid, setShowInvalid] = useState(false);
    const handleCloseInvalid = () => setShowInvalid(false);
    const [showFailure, setShowFailure] = useState(false);
    const handleCloseFailure = () => setShowFailure(false);

    return (
        <div className="contact-form-container">
            <div className="contact-form-heading">
                Contact Form
            </div>
            <text className={formError.name ? "" : "hidden" }><FaExclamationCircle/> Please enter your name</text>
            <input className={formError.name ? "form-error" : "" } id="inputName" type="text" name="name" placeholder="Name" value={formValue.name} onChange={handleChange}/>
            <text className={formError.email ? "" : "hidden" }><FaExclamationCircle/> Please enter a valid email address</text>
            <input className={formError.email ? "form-error" : "" } id="inputEmail" type="email" name="email" placeholder="Email Address" value={formValue.email} onChange={handleChange}/>
            <text className={formError.contactnumber ? "" : "hidden" }><FaExclamationCircle/> Please enter a valid contact number</text>
            <input className={formError.contactnumber ? "form-error" : "" } id="inputContactNumber" type="number" name="contactnumber" placeholder="Contact Number" value={formValue.contactnumber} onChange={handleChange}/>
            <text className={formError.message ? "" : "hidden" }><FaExclamationCircle/> Please enter at least 10 characters</text>
            <textarea className={formError.message ? "form-error" : "" } id="textAreaMessage" type="text" name="message" placeholder="Message" value={formValue.message} onChange={handleChange}/>
            <button className="contact-form-cta btn-color-highlight" onClick={handleValidate}>Send</button>

            <Modal show={showSuccess} centered>
                <Modal.Header>
                    <Modal.Title>Thank you for reaching out!</Modal.Title>
                </Modal.Header>
                <Modal.Body>We will get back to you as soon as we can.</Modal.Body>
                <Modal.Footer>
                    <button className="contact-form-cta btn-color-highlight" onClick={handleCloseSuccess}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={showInvalid} centered>
                <Modal.Header>
                    <Modal.Title>Oops!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please fill all the fields to proceed. Thank you.</Modal.Body>
                <Modal.Footer>
                    <button className="contact-form-cta btn-color-highlight" onClick={handleCloseInvalid}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={showFailure} centered>
                <Modal.Header>
                    <Modal.Title>Oops!</Modal.Title>
                </Modal.Header>
                <Modal.Body>There seems to be a problem. Please try again later.</Modal.Body>
                <Modal.Footer>
                    <button className="contact-form-cta btn-color-highlight" onClick={handleCloseFailure}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ContactForm
